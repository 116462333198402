<template>
  <TheHeader :role="`${creditor} header`" :partnerName="creditor" :class="`${creditor}-header`">
    <template #logos>
      <div class="d-flex align-center justify-start">
        <img class="creditor-logo" :src="`/images/partners/${creditor}/logo.png`" :alt="`Logo ${creditor}`"
          loading="lazy" />
        <NuxtLink to="/" class="d-flex">
          <img v-once class="logo" src="@/assets/logos/grayMeLembreLogo.png" alt="Logo Me lembre" loading="lazy" />
        </NuxtLink>
      </div>
    </template>
  </TheHeader>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
const route = useRoute();

const creditor = computed<string>(() => route.path.toString().split("/")[2]);
</script>

<style scoped lang="scss">
header .content .creditor-logo {
  display: unset;
}
</style>
